<template>
  <div class="app-container">
    <div class="flex-form">
      <el-form
        v-loading="loading"
        class="info-form"
        label-width="170px"
      >
        <el-form-item>
          <h2>
            View Store
          </h2>
        </el-form-item>

        <el-form-item label="Uid">
          <div class="info-field">
            {{ store.storeUid }}
          </div>
        </el-form-item>

        <el-form-item label="Category">
          <div class="info-field">
            {{ store.categoryName }}
          </div>
        </el-form-item>

        <el-form-item label="Name">
          <div class="info-field text-blue">
            {{ store.name }}
          </div>
        </el-form-item>

        <el-form-item label="Header Title">
          <div class="info-field text-blue">
            {{ store.headerTitle }}
          </div>
        </el-form-item>

        <el-form-item label="Header Text">
          <div class="info-field text-blue">
            {{ store.headerText }}
          </div>
        </el-form-item>

        <el-form-item label="Description">
          <div class="info-field text-blue">
            {{ store.description }}
          </div>
        </el-form-item>

        <el-form-item label="Description 2">
          <div class="info-field text-blue">
            {{ store.description2 }}
          </div>
        </el-form-item>

        <el-form-item label="Background Color">
          <div class="info-field">
            {{ store.bgColor }}
          </div>
        </el-form-item>

        <el-form-item label="Border Color">
          <div class="info-field">
            {{ store.circleColor }}
          </div>
        </el-form-item>

        <!-- <el-form-item label="Circle Color Alt">
          <div class="info-field">
            {{ store.circleColorAlt }}
          </div>
        </el-form-item> -->

        <el-form-item label="Text Color">
          <div class="info-field">
            {{ store.textColor }}
          </div>
        </el-form-item>

        <el-form-item
          v-if="iconUrl"
          label="Icon"
        >
          <div class="info-field">
            <el-image
              style="width: 100px; height: 100px"
              :src="iconUrl"
              fit="scale-down"
            />
          </div>
        </el-form-item>

        <el-form-item
          v-if="logoUrl"
          label="Logo"
        >
          <div class="info-field">
            <el-image
              style="width: 100px; height: 100px"
              :src="logoUrl"
              fit="scale-down"
            />
          </div>
        </el-form-item>

        <el-form-item label="Content Text">
          <div class="info-field text-blue">
            {{ store.website }}
          </div>
        </el-form-item>

        <el-form-item label="Facebook">
          <div class="info-field text-blue">
            {{ store.facebook }}
          </div>
        </el-form-item>

        <el-form-item label="Twitter">
          <div class="info-field text-blue">
            {{ store.twitter }}
          </div>
        </el-form-item>

        <el-form-item label="Instagram">
          <div class="info-field text-blue">
            {{ store.instagram }}
          </div>
        </el-form-item>

        <el-form-item label="LinkedIn">
          <div class="info-field text-blue">
            {{ store.linkedIn }}
          </div>
        </el-form-item>

        <el-form-item label="Youtube">
          <div class="info-field text-blue">
            {{ store.youtube }}
          </div>
        </el-form-item>

        <el-form-item label="Pinterest">
          <div class="info-field text-blue">
            {{ store.pinterest }}
          </div>
        </el-form-item>

        <el-form-item label="TikTok">
          <div class="info-field text-blue">
            {{ store.tiktok }}
          </div>
        </el-form-item>

        <el-form-item label="Active">
          <div class="info-field text-blue">
            {{ store.isActive ? 'Yes': 'No' }}
          </div>
        </el-form-item>

        <el-form-item label="Created At">
          <div class="info-field">
            {{ store.createdAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Updated At">
          <div class="info-field">
            {{ store.updatedAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Note">
          <div class="info-field text-blue">
            {{ store.note }}
          </div>
        </el-form-item>

        <el-form-item label="Parser Status">
          <div class="info-field">
            {{ store.parserStatus }}
          </div>
        </el-form-item>

        <el-form-item
          v-if="store.lastParserError"
          label="Last Parser Error"
        >
          <div class="info-field text-blue">
            {{ store.lastParserError }}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import permission from '@/directive/permission';
import { getStore } from '@/api/store';

export default {
  name: 'StoreView',
  directives: {
    permission,
  },
  data() {
    return {
      loading: false,
      storeUid: null,
      store: {},
    };
  },
  computed: {
    iconUrl() {
      return this.store?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.store?.media?.logo?.image?.location;
    },
  },
  watch: {
    userId() {
      this.getStore(this.storeUid);
    },
  },
  created() {
    this.storeUid = this.$route.params && this.$route.params.id;
    this.getStore(this.storeUid);
  },
  activated() {
    this.getStore(this.storeUid);
  },
  methods: {
    async getStore(storeUid) {
      try {
        this.loading = true;
        this.store = {};

        const data = await getStore({
          storeUid,
        });

        this.store = data?.store;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.$router.push({
          name: 'StoreList',
        });
      }
    },
  },
};
</script>
